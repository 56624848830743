@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

body {
	background-color: white;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

* {
	font-family: 'Poppins', Arial;
}

b {
	font-weight: 600;
}

.elipsis {
	font-weight: 600;
	color: #2379e8;
}

.mobile-hide-table-cell {
	display: none !important;
}

.trending-quote-table {
	width: 90%;
	padding-left: 1.5em;
	padding-right: 0.5em;
}

.trending-news {
	padding-left: 1.5em;
	padding-right: 0.5em;
}

.article-title {
	max-width: 260px !important;
}

.fs-075 {
	font-size: 9.75px !important;
}

.fs-0775 {
	font-size: 10.075px !important;
}

.fs-0875 {
	font-size: 11.375px !important;
}

.fs-1 {
	font-size: 13px !important;
}

.fs-1025 {
	font-size: 13.325px !important;
}

.fs-1075 {
	font-size: 13.975px !important;
}

.fs-1125 {
	font-size: 14.625px !important;
}

.fs-125 {
	font-size: 16.25px !important;
}

.fs-1875 {
	font-size: 24.375px !important;
}

@media only screen and (min-width: 800px) {
	.mobile-hide-table-cell {
		display: table-cell !important;
	}

	.notifications {
		position: fixed !important;
		left: 0 !important;
		top: 0 !important;
		padding-left: 1% !important;
		padding-right: 1% !important;
		width: 98% !important;
	}

	.email-container {
		width: 100% !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.trending-quote-table {
		width: 70% !important;
		padding-left: 3.5em !important;
	}

	.trending-news {
		padding-left: 3.5em !important;
	}

	.article-title {
		max-width: 540px !important;
	}

	.fs-075 {
		font-size: 12px !important;
	}

	.fs-0775 {
		font-size: 12.4px !important;
	}

	.fs-0875 {
		font-size: 14px !important;
	}

	.fs-1 {
		font-size: 16px !important;
	}

	.fs-1025 {
		font-size: 16.4px !important;
	}

	.fs-1075 {
		font-size: 17.2px !important;
	}

	.fs-1125 {
		font-size: 18px !important;
	}

	.fs-1125 {
		font-size: 18px !important;
	}

	.fs-125 {
		font-size: 20px !important;
	}

	.fs-1875 {
		font-size: 30px !important;
	}
}
